<template>
  <div class="diagnosis">
    <van-tree-select
      class="h100"
      :items="items"
      :main-active-index.sync="active"
      @click-nav="onNavClick"
    >
      <template #content>
        <template v-if="active === 0">
          <diagnosis-item
            v-model="formData.diagnosis"
            @next="active += 1"
          ></diagnosis-item>
        </template>
        <template v-else-if="active === 1">
          <van-field
            v-model="formData.medicalRecords.mainSuit"
            rows="20"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入主诉内容"
            show-word-limit
          />
        </template>
        <template v-else-if="active === 2">
          <van-field
            v-model="formData.doctorAdvice"
            rows="20"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入医嘱内容"
            show-word-limit
          />
        </template>
        <!-- 现病史 -->
        <template v-else-if="active === 3">
          <van-field
            v-model="formData.nowMedicalHistory"
            rows="20"
            autosize
            type="textarea"
            maxlength="100"
            placeholder="请输入现病史内容"
            show-word-limit
          />
        </template>
        <!-- 处方 -->
        <template v-else-if="active === 4">
          <recipe-item
            v-model="dictDrugList"
          ></recipe-item>
        </template>
        <!-- 检查 -->
        <template v-else-if="active === 5">
          <dictcheck-item
            v-model="checkDetailList"
          ></dictcheck-item>
        </template>
        <!-- 检验 -->
        <template v-else-if="active === 6">
          <dict-inspection-item
            v-model="inspectionDetailList"
          ></dict-inspection-item>
        </template>
      </template>
    </van-tree-select>

    <c-sticky
      :btns="[
        ...([6].includes(active) ? [] : [
          { text: '下一步', disabled: isDisabled(), click: onNext },
        ]),
        ...([2, 3, 4, 5, 6].includes(active) ? [
          { text: '完成医嘱', plain: true, click: onSubmit },
        ] : []),
      ]"
    ></c-sticky>
  </div>
</template>

<script>
import CSticky from '@/components/cSticky.vue';
import diagnosisItem from './components/diagnosis-item.vue';
import DictcheckItem from './components/dictcheck-item.vue';
import DictInspectionItem from './components/dictInspection-item.vue';
import RecipeItem from './components/recipe-item.vue';

export default {
  components: { diagnosisItem, CSticky, DictcheckItem, DictInspectionItem, RecipeItem },
  name: 'diagnosis',
  props: {
    type: String,
  },
  data() {
    return {
      businessId: this.$route.query.businessId,
      formData: {
        medicalRecords: {},
      },
      dictDrugList: [], // 处方
      checkDetailList: [], // 检查
      inspectionDetailList: [], // 检验
      active: 0,
      items: [
        { text: '临床(初步)诊断' },
        { text: '主诉' },
        { text: '医嘱' },
        { text: '现病史' },
        { text: '处方' },
        { text: '检查' },
        { text: '检验' },
      ],
    };
  },
  created() {
    this.getDiagnosisData();
  },
  methods: {
    onNext() {
      this.active += 1;
    },
    onSubmit() {
      if (!this.formData.diagnosis) {
        this.$toast('请选择诊断内容');
        setTimeout(() => {
          this.active = 0;
        }, 1500);
        return;
      }
      if (!this.formData.medicalRecords.mainSuit) {
        this.$toast('请输入主诉内容');
        this.active = 1;
        return;
      }
      if (!this.formData.doctorAdvice) {
        this.active = 2;
        this.$toast('请输入医嘱内容');
        return;
      }
      if (this.dictDrugList?.length && this.dictDrugList.some(dict => !dict.groupId || !dict.dose || !dict.giveType || !dict.frequencyCode || !dict.takeDays || !dict.count)) {
        this.$toast('请补全处方内的药品信息!');
        setTimeout(() => {
          this.active = 4;
        }, 1500);
        return;
      }
      if (this.checkDetailList?.length && this.checkDetailList.some(check => !check.count)) {
        this.$toast('请补全检查单里面的数量');
        setTimeout(() => {
          this.active = 5;
        }, 1500);
        return;
      }
      if (this.inspectionDetailList?.length && this.inspectionDetailList.some(check => !check.count)) {
        this.$toast('请补全检验单里面的数量');
        setTimeout(() => {
          this.active = 6;
        }, 1500);
        return;
      }
      const params = {
        ...this.formData,
        businessId: this.businessId,
        check: {
          checkDetailList: this.checkDetailList,
        },
        inspection: {
          inspectionDetailList: this.inspectionDetailList,
        },
        recipeList: [{
          dictDrugList: this.dictDrugList,
          recipeType: '1',
        }],
        diagnosisStatus: 2,
      };
      this.$post(`/api/blade-consult/doctorWork/saveIllnessInfoResult`, params)
        .then(() => {
          this.$toast.success('操作成功');
          if (this.type === 'video') {
            this.$emit('update');
          } else this.$router.back();
        });
    },
    onNavClick(index) {
      if (index === this.active) return;
      this.active = index;
    },
    isDisabled() {
      // 诊断
      if (this.active === 0) {
        return !this.formData.diagnosis;
      }
      // 主诉
      if (this.active === 1) {
        return !this.formData.medicalRecords.mainSuit;
      }
      // 医嘱
      if (this.active === 2) {
        return !this.formData.doctorAdvice;
      }
      // 检查
      if (this.active === 5) {
        return !this.checkDetailList || !this.checkDetailList.length;
      }
      // 检验
      if (this.active === 6) {
        return !this.inspectionDetailList || !this.inspectionDetailList.length;
      }
      return false;
    },
    getDiagnosisData() {
      this.$post(`/api/blade-consult/doctorWork/getIllnessInfoResult/${this.businessId}`)
        .then(({ data }) => {
          this.formData = {
            ...data,
          };
          this.inspectionDetailList = this.formData.inspection?.inspectionDetailList || [];
          this.checkDetailList = this.formData.check?.checkDetailList || [];
          this.dictDrugList = this.formData.recipeList ? this.formData.recipeList.map(recipe => recipe.dictDrugList) : [];
        });
    },
  },
};
</script>

<style lang="less" scoped>
.diagnosis {
  min-height: 80vh;
  .h100 {
    height: 100% !important;
  }
}
</style>
