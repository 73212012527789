<template>
  <div class="dialog">
    <van-dialog
      v-model="dialog.visible"
      class="dialog"
      :title="dialog.title || '提示'"
      cancelButtonColor="#999"
      confirmButtonColor="#0081E3"
      :confirmButtonText="dialog.confirmButtonText || '确认'"
      :cancelButtonText="dialog.cancelButtonText || '取消'"
      :showCancelButton="dialog.showCancelButton === false ? false : true"
      :showConfirmButton="dialog.showConfirmButton === false ? false : true"
      :before-close="onBeforeClose"
      v-on="$listeners"
    >
      <slot></slot>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'CDialog',
  props: {
    dialog: {
      type: Object,
      default: () => ({
        visible: false,
        title: '提示',
      }),
    },
  },
  methods: {
    onBeforeClose(action, done) {
      if (action === 'confirm') {
        setTimeout(() => done(false), 1000);
      } else {
        done();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialog {
  padding: 12px;
  /deep/ .van-dialog__header {
    font-size: 16px;
    padding-top: 12px;
    margin-bottom: 12px;
  }
  /deep/ .van-dialog__content {
    padding: 12px 0;
    overflow: hidden auto;
  }
}
</style>
