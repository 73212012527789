<template>
  <div class="h100">
    <div v-if="!value" class="primary h100 flex-center-center" @click="pickerShow = true">
      <van-icon name="plus" />
      <div>新建诊断</div>
    </div>

    <div v-else class="dark fs18 fw500 h100 mb12 flex-center-center flex-column">
      <div class="mr6">
        {{ value }}
      </div>
      <div class="primary fs12" @click="pickerShow = true">修改</div>
    </div>

    <van-popup v-model="pickerShow" title="请选择" class="pop-class" position="bottom" get-container="body">
      <van-search
        v-model="diagnosisName"
        show-action
        placeholder="请输入关键词搜索"
        @input="onSearch"
      >
        <template #action>
          <div @click="onClose">确定</div>
        </template>
      </van-search>

      <van-cell-group class="list" v-if="list.length">
        <van-cell
          v-for="item in list"
          :key="item.id"
          :title="item.diagnosisName"
          center
          @click="handleSelect(item)"
        >
        </van-cell>
      </van-cell-group>
      <div v-else class="empty-data list">暂无数据</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'diagnosisItem',
  props: ['value'],
  data() {
    return {
      pickerShow: false,
      list: [],
      diagnosisName: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleSelect(item) {
      this.diagnosisName = item.diagnosisName;
      this.$emit('input', item.diagnosisName);
      this.onClose();
    },
    onNext() {
      this.$emit('next');
    },
    onClose() {
      this.pickerShow = false;
    },
    onSearch() {
      this.getList();
    },
    getList() {
      this.$post(`/api/blade-consult/dict/diagnosis/page`, {
        page: 1,
        size: 50,
        diagnosisName: this.diagnosisName,
      }).then(({ data }) => {
        this.list = data.records || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  height: calc(100vh - 54px);
  overflow: auto;
}
</style>
