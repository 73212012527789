<template>
  <div class="h100">
    <div v-if="!value || !value.length" class="primary h100 flex-center-center" @click="pickerShow = true">
      <van-icon name="plus" />
      <div>新增处方</div>
    </div>

    <div v-else class="h100">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in checkedList"
          :key="item.id"
          :title="item.drugName"
          center
        >
          <template #label>
            <div class="flex-start-center">
              <div v-if="item.frequencyCode" class="info">{{ item.frequencyCode }}|</div>
              <div v-if="item.giveType" class="info">{{ getGiveType(item.giveType) }}|</div>
              <div v-if="item.takeDays" class="info">{{ item.takeDays }}天|</div>
              <div v-if="item.dose" class="info">{{ item.dose }}{{ item.drugUnitStr }}|</div>
              <div v-if="item.count" class="info">{{ item.count }}{{ item.drugPackaging }}</div>
            </div>
          </template>
          <template #right-icon>
            <div>
              <van-icon name="edit" class="mr6 primary" @click="showDialog(item, index)" />
              <van-icon name="cross" class="mr6 danger-color" @click="onDelete(index)" />
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="primary flex-center-center" @click="pickerShow = true">
        <van-icon name="plus" />
        <div>新增处方</div>
      </div>
    </div>

    <van-popup v-model="pickerShow" title="请选择" class="pop-class" position="bottom" get-container="body">
      <van-search
        v-model="drugName"
        show-action
        placeholder="请输入关键词搜索"
        @input="onSearch"
      >
        <template #action>
          <span @click="pickerShow = false">取消</span>
        </template>
      </van-search>

      <van-cell-group class="list" v-if="computedList.length">
        <van-cell
          title="药品名称"
          value="库存"
          value-class="tac mr12"
        >
          <template #right-icon>
            <a class="info">价格(元)</a>
          </template>
        </van-cell>
        <van-cell
          v-for="item in computedList"
          :key="item.id"
          :title="item.drugName"
          :value="item.stock"
          :label="item.drugSpecifications"
          :class="item.checked ? 'primary' : ''"
          :value-class="item.checked ? 'primary tac' : 'tac'"
          center
          @click="handleSelect(item)"
        >
          <template #right-icon>
            <span :class="item.checked ? 'primary' : 'info'">{{ item.priceStr }}</span>
            <van-icon name="success" :class="item.checked ? 'primary' : 'white'" />
          </template>
        </van-cell>
      </van-cell-group>
      <div v-else class="empty-data list">暂无数据</div>
    </van-popup>

    <c-dialog
      :dialog="dialog"
      @confirm="onConfirm"
    >
      <c-form
        ref="cForm"
        v-model="dialog.data"
        :columns="formColumns"
      ></c-form>
    </c-dialog>
  </div>
</template>

<script>
import cDialog from '@/components/cDialog.vue';
import CForm from '@/components/cForm.vue';

const numberList = (num) => Array.from({ length: num }).map((_, index) => ({ label: index + 1, value: index + 1 }));

export default {
  components: { cDialog, CForm },
  name: 'dictcheckItem',
  props: ['value'],
  data() {
    return {
      pickerShow: false,
      list: [],
      unitList: [], // 单位
      gytjList: [], //
      rateList: [], // 频率
      giveTypeList: [], // 给药方式
      drugName: '',
      dialog: {
        visible: false,
        data: {},
      },
    };
  },
  computed: {
    formColumns() {
      return [
        {
          type: 'select',
          prop: 'groupId',
          payload: {
            label: '组号',
            key: 'groupId',
            labelKey: 'label',
            valueKey: 'value',
            columns: numberList(10),
          },
        },
        { label: '药物名称', prop: 'drugName', required: true, disabled: true, rules: [{ required: true, message: '' }] },
        { label: '单次剂量', prop: 'dose', type: 'number', extra: this.dialog.data.drugUnitStr, required: true, rules: [{ required: true, message: '' }] },
        {
          type: 'select',
          prop: 'giveType',
          payload: {
            label: '给药方式',
            key: 'giveType',
            labelKey: 'give_type_name',
            valueKey: 'give_type_code',
            columns: this.giveTypeList,
            required: true,
            rules: [{ required: true, message: ' ' }],
          },
        },
        {
          type: 'select',
          prop: 'frequencyCode',
          payload: {
            label: '频率',
            key: 'frequencyCode',
            labelKey: 'frequencyExpression',
            valueKey: 'frequencyCode',
            columns: this.rateList,
            required: true,
            rules: [{ required: true, message: ' ' }],
          },
        },
        {
          type: 'select',
          prop: 'takeDays',
          payload: {
            label: '用药天数',
            key: 'takeDays',
            labelKey: 'label',
            valueKey: 'value',
            columns: numberList(30),
            required: true,
            rules: [{ required: true, message: ' ' }],
          },
        },
        { label: '发药数量', prop: 'count', required: true, extra: this.dialog.data.drugPackaging, rules: [{ required: true, message: '' }] },
      ];
    },
    checkedList: {
      get() {
        return this.value;
      },
      set(list) {
        this.$emit('input', list);
      },
    },
    computedList() {
      return this.list.map(ll => ({
        ...ll,
        checked: this.checkedList.map(check => check.id).includes(ll.id),
      }));
    },
  },
  created() {
    this.getList();
    this.getDrugRateUnit();
  },
  methods: {
    // 保存检查表单内容
    async onConfirm() {
      await this.$refs.cForm.$refs.vanForm.validate();
      this.$set(this.checkedList, this.checkedIndex, {
        ...this.dialog.data,
      });
      this.dialog = {
        visible: false,
        data: {},
      };
    },
    getGiveType(type) {
      return this.giveTypeList?.find(drug => drug.give_type_code === type)?.give_type_name;
    },
    showDialog(item, index) {
      this.dialog = {
        title: '修改',
        visible: true,
        data: {
          ...item,
        },
      };
      this.checkedIndex = index;
    },
    onDelete(index) {
      this.$dialog.confirm({
        title: '警告',
        message: '确认删除当前项?',
      }).then(() => {
        this.checkedList.splice(index, 1);
      });
    },
    handleSelect(item) {
      if (!item.stock) {
        this.$toast('库存为0不能选择');
        return;
      }
      // 取消选择
      if (this.checkedList.map(check => check.id).includes(item.id)) {
        this.checkedList = this.checkedList.filter(check => check.id !== item.id);
      } else {
        this.checkedList.push(item);
      }
    },
    onClose() {
      this.pickerShow = false;
    },
    onSearch() {
      this.getList();
    },
    // 获取药品字典
    getDrugRateUnit() {
      this.$post('/api/blade-consult/dictdrug/getDrugDictResp')
        .then(({ data }) => {
          this.rateList = data.frequencyList || [];
          this.gytjList = data.gyfsList || [];
          this.unitList = data.bzdwList || [];
        });
    },
    // 处方列表
    getList() {
      this.$post(`/api/blade-consult/dictdrug/list`, {
        key: this.drugName,
      }).then(({ data }) => {
        this.list = data || [];
        this.giveTypeList = this.list[0]?.drugUsageObj || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  height: calc(100vh - 54px);
  overflow: auto;
}
</style>
