<template>
  <div class="h100">
    <div v-if="!value || !value.length" class="primary h100 flex-center-center" @click="pickerShow = true">
      <van-icon name="plus" />
      <div>新建检验</div>
    </div>

    <div v-else class="h100">
      <van-cell-group>
        <van-cell
          v-for="(item, index) in checkedList"
          :key="item.id"
          center
        >
          <template #title>
            <div class="flex-between-center">
              <div class="dark fw500 mw20vw">{{ item.inspectionName }}</div>
              <div class="normal">{{ item.count }}{{ item.projectUnit }}</div>
              <div class="info">{{ item.sampleName }}</div>
              <div>
                <van-icon name="edit" class="mr6 primary" @click="showDialog(item, index)" />
                <van-icon name="cross" class="mr6 danger-color" @click="onDelete(index)" />
              </div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="primary flex-center-center" @click="pickerShow = true">
        <van-icon name="plus" />
        <div>新建检验</div>
      </div>
    </div>

    <van-popup v-model="pickerShow" title="请选择" class="pop-class" position="bottom" get-container="body">
      <van-search
        v-model="inspectionName"
        show-action
        placeholder="请输入关键词搜索"
        @input="onSearch"
      >
        <template #action>
          <div @click="onClose">确定</div>
        </template>
      </van-search>

      <van-cell-group class="list" v-if="computedList.length">
        <van-cell
          v-for="item in computedList"
          :key="item.id"
          :title="item.inspectionName"
          :class="item.checked ? 'primary' : ''"
          center
          @click="handleSelect(item)"
        >
          <template v-if="item.checked" #right-icon>
            <van-icon name="success" class="primary" />
          </template>
        </van-cell>
      </van-cell-group>
      <div v-else class="empty-data list">暂无数据</div>
    </van-popup>

    <c-dialog
      :dialog="dialog"
      @confirm="onConfirm"
    >
      <c-form
        ref="cForm"
        v-model="dialog.data"
        :columns="formColumns"
      ></c-form>
    </c-dialog>
  </div>
</template>

<script>
import cDialog from '@/components/cDialog.vue';
import CForm from '@/components/cForm.vue';

export default {
  components: { cDialog, CForm },
  name: 'dictcheckItem',
  props: ['value'],
  data() {
    return {
      pickerShow: false,
      list: [],
      sampleList: [],
      inspectionName: '',
      dialog: {
        visible: false,
        data: {},
      },
    };
  },
  computed: {
    formColumns() {
      return [
        { label: '检验项目名称', prop: 'inspectionName', required: true, rules: [{ required: true, message: '' }] },
        {
          type: 'select',
          prop: 'sampleName',
          payload: {
            label: '样本',
            key: 'sampleName',
            labelKey: 'sampleName',
            valueKey: 'sampleName',
            columns: this.sampleList,
            required: true,
            rules: [{ required: true, message: ' ' }],
          },
        },
        { label: `数量(${this.dialog.data.projectUnit})`, prop: 'count', type: 'digit', required: true, rules: [{ required: true, message: '' }] },
      ];
    },
    checkedList: {
      get() {
        return this.value;
      },
      set(list) {
        this.$emit('input', list);
      },
    },
    computedList() {
      return this.list.map(ll => ({
        ...ll,
        checked: this.checkedList.map(check => check.id).includes(ll.id),
      }));
    },
  },
  created() {
    this.getList();
    this.getCheckTypeList();
  },
  methods: {
    // 保存检查表单内容
    async onConfirm() {
      await this.$refs.cForm.$refs.vanForm.validate();
      this.$set(this.checkedList, this.checkedIndex, {
        ...this.dialog.data,
      });
      this.dialog = {
        visible: false,
        data: {},
      };
    },
    showDialog(item, index) {
      this.dialog = {
        title: '修改',
        visible: true,
        data: {
          ...item,
        },
      };
      this.checkedIndex = index;
    },
    onDelete(index) {
      this.$dialog.confirm({
        title: '警告',
        message: '确认删除当前项?',
      }).then(() => {
        this.checkedList.splice(index, 1);
      });
    },
    handleSelect(item) {
      // 取消选择
      if (this.checkedList.map(check => check.id).includes(item.id)) {
        this.checkedList = this.checkedList.filter(check => check.id !== item.id);
      } else {
        this.checkedList.push(item);
      }
    },
    onClose() {
      this.pickerShow = false;
    },
    onSearch() {
      this.getList();
    },
    getCheckTypeList() {
      this.$post(`/api/blade-consult/dictinspectionsample/page`, {
        page: 1,
        size: 1000,
        status: 1,
      }).then(({ data }) => {
        this.sampleList = data.records || [];
      });
    },
    getList() {
      this.$post(`/api/blade-consult/dictinspectionproject/page`, {
        page: 1,
        size: 50,
        inspectionName: this.inspectionName,
        status: 1,
      }).then(({ data }) => {
        this.list = data.records || [];
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  height: calc(100vh - 54px);
  overflow: auto;
}
.mw20vw {
  max-width: 20vw;
}
</style>
