<template>
  <div class="sticky">
    <slot name="status">
      <van-row class="status" v-if="status" @click="onStatusClick">
        <a class="primary">{{ status }}</a>
      </van-row>
    </slot>
    <van-row :gutter="layout.gutter">
      <van-col v-for="(btn, index) in btns" :key="index" :span="layout.span" class="pdt8">
        <van-button
          :type="btn.type || 'info'"
          block
          :plain="btn.plain"
          :class="btn.class"
          :loading="cLoading"
          :disabled="btn.disabled"
          @click="onClick(btn)"
        >
          {{ btn.text }}
        </van-button>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: 'CSticky',
  props: {
    status: String,
    btns: Array,
  },
  data() {
    return {
      cLoading: false,
    };
  },
  computed: {
    layout() {
      const len = this.btns.length;
      return {
        span: len > 2 ? 12 : 24 / len,
        gutter: len > 1 ? 10 : 0,
      };
    },
  },
  methods: {
    onClick(btn) {
      this.cLoading = true;
      setTimeout(() => {
        this.cLoading = false;
      }, 1000);
      if (btn.click) btn.click();
    },
    onStatusClick() {
      this.$emit('statusClick');
    },
  },
};
</script>

<style lang="less" scoped>
.sticky {
  position: fixed;
  bottom: 12px;
  left: 12px;
  right: 12px;
  text-align: center;
  z-index: 10;
  .status {
    margin-bottom: 12px;
  }
}
</style>
