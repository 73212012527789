<template>
  <div class="select">
    <van-field
      :label="payload.label"
      :name="payload.labelKey"
      :readonly="payload.readonly === false ? false : true"
      :required="payload.required"
      :value="selectedValue"
      placeholder="请选择"
      clickable
      :disabled="payload.disabled"
      :type="payload.inputType || 'text'"
      :rules="payload.rules"
      :input-align="payload.inputAlign || 'right'"
      @click="showPicker"
    >
      <template #button>
        <slot>
          <van-icon v-if="value[payload.key] && clearable" name="clear" @click.stop="onClear" />
          <van-icon class="ml6" name="arrow-down" />
        </slot>
      </template>
    </van-field>

    <van-popup v-model="pickerShow" position="bottom" get-container="body">
      <van-datetime-picker
        v-if="payload.type === 'date'"
        v-model="currentDate"
        title="请选择时间"
        :type="payload.dateType"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onDateConfirm"
        @cancel="onCancel"
      />
      <van-picker
        v-else
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'CSelect',
  props: {
    payload: {
      type: Object,
      default: () => ({
        placeholder: '请选择',
        valueKey: '', // 列表的value
        labelKey: '', // 列表的label
        label: '',
        key: '', // 需要传的字段
        type: 'select', // 支持date
        dateType: 'datetime',
        columns: [],
        rules: [],
      }),
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    value: Object,
  },
  data() {
    return {
      selectedValue: '',
      pickerShow: false,
      pickerType: '',
      minDate: this.payload.minDate || new Date(2020, 0, 1),
      maxDate: new Date(2050, 10, 1),
      currentDate: new Date(),
    };
  },
  computed: {
    columns() {
      if (!this.payload.columns || !this.payload.columns.length) return [];
      return this.payload.columns.map(col => ({ ...col, text: col[this.payload.labelKey] }));
    },
    formatDateType() {
      if (this.payload.dateType === 'datetime') {
        return 'YYYY-MM-DD HH:mm:ss';
      }
      if (this.payload.dateType === 'year-month') {
        return 'YYYY-MM';
      }
      return 'YYYY-MM-DD';
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(form) {
        if (this.payload.type === 'date') {
          this.selectedValue = this.value[this.payload.key];
          return;
        }
        const { columns = [], valueKey, labelKey, key } = this.payload;
        this.selectedValue = columns.find(col => col[valueKey] === form[key])?.[labelKey];
      },
    },
    columns: {
      deep: true,
      handler(list) {
        if (list?.length) {
          const { valueKey, labelKey, key } = this.payload;
          this.selectedValue = list.find(col => col[valueKey] === this.value[key])?.[labelKey];
        }
      },
    },
  },
  methods: {
    showPicker() {
      if (this.payload.disabled) return;
      this.pickerShow = true;
    },
    onCancel() {
      this.value[this.payload.key] = '';
      // this.value[this.payload.valueKey] = '';
      this.$emit('input', {
        ...this.value,
      });
      this.pickerShow = false;
      this.$emit('cancel');
    },
    onConfirm(item) {
      if (!item) return;
      // this.value[this.payload.labelKey] = item[this.payload.labelKey] || item.text;
      // this.value[this.payload.valueKey] = item[this.payload.valueKey] || item.value;
      this.value[this.payload.key] = item[this.payload.valueKey] || item.value;
      this.$emit('input', {
        ...this.value,
      });
      this.$emit('change', this.value[this.payload.key]);
      this.pickerShow = false;
    },
    onDateConfirm(value) {
      this.value[this.payload.key] = this.$dayjs(value).format(this.formatDateType);
      this.$emit('input', {
        ...this.value,
      });
      this.pickerShow = false;
    },
    onClear() {
      this.$emit('input', {
        ...this.value,
        [this.payload.key]: '',
      });
      this.$emit('change');
      this.pickerShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.select {
  position: relative;
}
.select::after {
  position: absolute;
  box-sizing: border-box;
  content: ' ';
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 1px solid #ebedf0;
  -webkit-transform: scaleY(.5);
  transform: scaleY(.5);
}
</style>
