<template>
  <van-form
    ref="vanForm"
    class="c-form"
    v-bind="$attrs"
  >
    <div v-for="item in columns" :key="item.prop">
      <van-field
        v-bind="item"
        v-if="['text', 'textarea', 'tel', 'digit', 'number', 'password'].includes(item.type) || !item.type"
        v-model.trim="value[item.prop]"
        :label="item.label"
        :placeholder="item.placeholder || '请输入'"
        :key="item.prop"
        :name="item.prop"
        :input-align="item.inputAlign || 'right'"
        :clearable="item.clearable === false ? false : true"
        :maxlength="item.maxlength || 20"
        autosize
        rows="1"
        @input="onInput(item)"
        v-on="item.on"
      >
        <template #button>
          <slot :name="`${item.prop}Button`"></slot>
        </template>
        <template #extra>
          <slot :name="`${item.prop}Extra`">{{ item.extra }}</slot>
        </template>
      </van-field>
      <van-field
        v-bind="item"
        v-else-if="item.type === 'radio'"
        :label="item.label"
        :name="item.prop"
        input-align="right"
      >
        <template #input>
          <van-radio-group v-model="value[item.prop]" direction="horizontal" icon-size="16" class="radio-group" :disabled="item.disabled">
            <van-radio
              v-for="radio in item.list"
              class="radio-item"
              :key="radio[item.valueKey || 'value']"
              :name="radio[item.valueKey || 'value']"
            >
              {{ radio[item.labelKey || 'label'] }}
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        :key="item.prop"
        :label="item.label"
        input-align="right"
        v-else-if="item.type === 'stepper'"
      >
        <template #input>
          <van-stepper
            v-bind="item"
            class="w100"
            v-model="value[item.prop]"
            :min="0"
            :placeholder="item.placeholder || '请输入'"
            default-value=""
            input-width="80px"
            v-on="item.on"
          ></van-stepper>
        </template>
      </van-field>
      <c-select
        v-else-if="['select', 'date'].includes(item.type)"
        :key="item.prop"
        :value="value"
        :clearable="item.clearable === false ? false : true"
        :payload="item.payload"
        @input="val => $emit('input', val)"
        @change="$emit('change')"
        @cancel="$emit('cancel')"
      ></c-select>
      <c-multiple-select
        v-else-if="['multiple-select'].includes(item.type)"
        :key="item.prop"
        :value="value"
        :clearable="item.clearable === false ? false : true"
        :payload="item.payload"
        @change="$emit('change')"
        @input="val => $emit('input', val)"
      ></c-multiple-select>
      <slot v-else-if="item.type === 'slot'" :name="item.prop"></slot>
    </div>
  </van-form>
</template>

<script>
import CMultipleSelect from './cMultipleSelect.vue';
import cSelect from './cSelect.vue';

export default {
  components: { cSelect, CMultipleSelect },
  name: 'CForm',
  props: {
    value: Object,
    /**
     * [
     *  type: text | textarea | tel | digit | number | password | select | date | multiple-select,
     * ]
     */
    columns: Array,
  },
  data() {
    return {};
  },
  methods: {
    onInput(item) {
      if (this.value[item.prop].length > (item.maxlength || 20)) {
        this.value[item.prop] = this.value[item.prop].slice(0, (item.maxlength || 20));
      }
    },
  },
};
</script>

<style lang="less" scoped>
.radio-group {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  .radio-item {
    margin-right: 6px;
  }
}
</style>
