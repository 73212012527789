<template>
  <div class="select">
    <van-field
      :label="payload.label"
      :name="payload.key"
      readonly
      :disabled="payload.disabled"
      :required="payload.required"
      :rules="payload.rules"
      placeholder="请选择"
      @click="showPicker"
    >
      <template #button>
        <slot>
          <van-icon v-if="clearable && value[payload.key] && value[payload.key].length" name="clear" @click.stop="onClear" />
          <van-icon class="ml6" name="arrow-down" />
        </slot>
      </template>
      <template #input>
        <van-tag
          plain
          round
          size="medium"
          :type="payload.disabled ? 'info' : 'primary'"
          v-for="tag in tagList"
          :key="tag"
          class="mr6 mb4"
        >
          {{ tag }}
        </van-tag>
      </template>
    </van-field>
    <van-popup v-model="pickerShow" title="请选择" position="bottom" round>
      <!-- 标签需要多选,vant没有提供,只能自己写 -->
      <div class="popup">
        <div class="flex-between-center btns mb12">
          <a class="info" @click="onCancel">取消</a>
          <a class="primary" @click="onConfirm">确认</a>
        </div>
        <div class="mb12 ml12">
          <a class="primary" @click="toggleSelectedAll">{{ isCheckedAll ? '取消全选' : '全选' }}</a>
        </div>
        <van-checkbox-group v-model="selected">
          <van-cell
            v-for="item in columns"
            :key="item[payload.valueKey]"
            class="flex-center-center flex-start checkbox-item"
          >
            <van-checkbox
              :name="item[payload.valueKey]"
              @change="toggleSelected($event, item)"
            >
              {{ item[payload.labelKey] }}
            </van-checkbox>
          </van-cell>
        </van-checkbox-group>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'CMultipleSelect',
  props: {
    payload: {
      type: Object,
      default: () => ({
        columns: [], // 整个列表数据,[{}]
        label: '',
        labelKey: '',
        valueKey: '',
        key: '', // 字段名
        required: false,
        rules: [],
      }),
    },
    value: Object,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(obj) {
        const { key, columns, labelKey, valueKey } = this.payload;
        if (obj && obj[key] && obj[key].length) {
          this.selected = obj[key];
          this.isCheckedAll = columns.every(col => this.selected.includes(col[valueKey]));
          this.tagList = columns.filter(col => obj[key].includes(col[valueKey])).map(col => col[labelKey]);
        } else {
          this.tagList = [];
        }
      },
    },
    columns: {
      deep: true,
      handler(list) {
        if (list?.length) {
          const { valueKey, labelKey, key } = this.payload;
          if (!this.value[key] || !this.value[key].length) return;
          this.selected = this.value[key];
          this.tagList = list.filter(col => this.selected.includes(col[valueKey])).map(col => col[labelKey]);
          this.isCheckedAll = list.every(col => this.selected.includes(col[valueKey]));
        }
      },
    },
    selected(selected) {
      this.isCheckedAll = this.payload.columns.every(col => selected.includes(col[this.payload.valueKey]));
    },
  },
  data() {
    return {
      pickerShow: false,
      selected: [], // 被选中的数据
      isCheckedAll: false,
      tagList: [],
    };
  },
  computed: {
    columns() {
      return this.payload.columns;
    },
  },
  methods: {
    showPicker() {
      if (this.payload.disabled) return;
      this.pickerShow = true;
    },
    // 切换全选
    toggleSelectedAll() {
      if (!this.isCheckedAll) {
        this.selected = this.columns.map(col => col[this.payload.valueKey]);
      } else {
        this.selected = [];
      }
      this.isCheckedAll = !this.isCheckedAll;
    },
    // 切换选中
    toggleSelected(bool, item) {
      if (bool) {
        this.selected.push(item[this.payload.valueKey]);
      } else {
        this.selected = this.selected.filter(sel => sel !== item[this.payload.valueKey]);
      }
    },
    // 取消时将选中的清空
    onCancel() {
      const { key } = this.payload;
      this.$emit('input', {
        ...this.value,
        [key]: [],
      });
      this.$emit('cancel');
      this.tagList = [];
      this.selected = [];
      this.pickerShow = false;
    },
    onConfirm() {
      const { key, labelKey, valueKey } = this.payload;
      this.$emit('input', {
        ...this.value,
        [key]: this.selected,
      });
      this.tagList = this.columns.filter(col => this.selected.includes(col[valueKey])).map(col => col[labelKey]);
      this.$emit('change', this.selected);
      this.pickerShow = false;
    },
    onClear() {
      const { key } = this.payload;
      this.$emit('input', {
        ...this.value,
        [key]: [],
      });
      this.tagList = [];
      this.selected = [];
      this.$emit('change');
      this.pickerShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.popup {
  padding-top: 12px;
  padding-bottom: 24px;
  .btns {
    padding: 4px 16px;
    // margin-bottom: 36px;
  }
  .checkbox-item {
    padding: 12px;
  }
  /deep/ .van-checkbox-group {
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
}
/deep/ .van-field__control--custom {
  flex-wrap: wrap;
}
</style>
